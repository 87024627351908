import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { VerifyAccountPage } from '~/components/VerifyAccount';

const VerifyAccount: React.FC<PageProps> = props => (
  <>
    <SEO pageContext={props.pageContext} title="VerifyAccount.pageTitle" />
    <VerifyAccountPage {...props} />
  </>
);

export default VerifyAccount;
