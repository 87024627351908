import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageProps } from 'gatsby';
import { getUrlParam } from '~/common/urlParams';
import { verifyAccount } from '~/services/auth';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useNavigate } from '~/state/hooks/useNavigate';
import { logger, ONMFeature } from '~/services/logging';
import { LoadingPage } from '../Loading';

export const VerifyAccountPage: React.FC<PageProps> = props => {
  const { showSnackbar } = useSnackbar();
  const { navigate } = useNavigate();
  const { t } = useTranslation();

  const redirectToSignIn = () => {
    navigate('signin');
  };

  const handleVerifyAccountSuccess = () => {
    showSnackbar(t(`VerifyAccount.success`), 'success', redirectToSignIn);
  };

  const handleVerifyAccountError = (error: Error) => {
    const isHarmlessError = error.message
      .toLowerCase()
      .includes('current status is confirmed');

    if (isHarmlessError) {
      return handleVerifyAccountSuccess();
    }

    logger.error(ONMFeature.ACCOUNT_ACTIVATION, error);

    showSnackbar(t(`VerifyAccount.error`), 'error', redirectToSignIn);
  };

  useEffect(() => {
    const userId = getUrlParam(props.location, 'userId');
    let confirmationCode = getUrlParam(props.location, 'confirmationCode');

    if (confirmationCode?.charAt(confirmationCode.length - 1) === '/') {
      confirmationCode = confirmationCode.slice(0, -1);
    }

    verifyAccount(userId, confirmationCode)
      .then(handleVerifyAccountSuccess)
      .catch(handleVerifyAccountError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />;
};
