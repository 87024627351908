/*
 * Function Get URL Parameter
 *
 * @param String prop The specific URL parameter you want to retreive the value for
 * @param Object -> Location prop. From the calling page (props.location)
 * @return String|Object If prop is provided a string value is returned, otherwise an object of all properties is returned(Improvement)
 */
export const getUrlParam = (
  locationProps: Location,
  queryString: string
): string => {
  // No parameters
  if (!locationProps || !queryString) return null;
  // Single Parameter
  const urlSearchParam = new URLSearchParams(locationProps.search);
  const parsedSearchParam = urlSearchParam.get(queryString);
  /*
    If we need to brush through multiple URL params in future,
    we have to implement the logic below and return the required list
  */
  return parsedSearchParam;
};
